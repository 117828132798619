@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/Inter-VariableFont.ttf');
}

.message-display {
  background-color: #1f1f23;
  padding: 10px;
  height: inherit;
  overflow-y: scroll;

  border-top: solid;
  border-color: #0e0e10;
  border-width: 4px;
}

.message-item {
  margin: 10px;
}

.message {
  font-family: 'inter';
  font-size: 10pt;
  color: #EFEFF1;
  overflow-wrap: break-word;
  
}

.timestamp {
  color: #adadb8;
  margin: 0 2px 0 0;
}

.sender {
  margin: 0 2px;
}

.platform {
  margin-right: 10px;
}

.emote-twitch {
  vertical-align: middle;
}

svg {
  vertical-align: middle;
}