@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit-ExtraBoldItalic.ttf');
  font-weight: bolder;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit-SemiBoldItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}


.app {
  background-color: #0e0e10;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;

  display: flex;
  flex-direction: column;
}

nav {
  background-color: #1f1f23;

  display: flex;
  justify-content: space-between;

  align-items: center;
  
  padding: 10px 25px;
  height: 50px;
}

.nav-button {
  background-color: #EFEFF1;
  border: none;
  border-radius: 50%;

  margin-right: 1%;

  width: 30px;
  height: 30px;

  font-family: 'Kanit';
  font-weight: normal;
  font-style: normal;
  font-size: 14pt;

  align-items: center;
}

button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

h1 {
  font-family: 'Kanit';
  font-weight: bold;
  font-style: italic;
  font-size: 24pt;
  color: #1f1f23;
}

form {
  padding: 4% 15%
}

label {
  font-family: 'Kanit';
  font-weight: normal;
  font-style: italic;
  font-size: 11pt;
  color: #EFEFF1;
  letter-spacing: 0.8px;
  margin: 2px;

}

input {
  background-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0.5pt;
  border-color: #EFEFF1;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 2px;

  font-family: 'Kanit';
  font-weight: normal;
  font-style: normal;
  color: #EFEFF1;
  font-size: 11pt;
}

.form-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.form-button {
  background-color: #EFEFF1;
  border: none;
  border-radius: 2pt;

  padding: 0px 10px 0px 50px;
  font-family: 'Kanit';
  font-weight: bolder;
  font-style: italic;
  font-size: 11pt;

  float: right;
}

.button-inverse {
  background-color: #EFEFF1;
  padding: 0px 15px;
  align-content: center;
  width: max-content;
  overflow: hidden;
  border-radius: 5px;
}